// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from './firebase'; // import your Firebase auth instance

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Listener for authentication state
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });

    // Clean up the listener when component unmounts
    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    // Implement your login logic with Firebase
  };

  const logout = async () => {
    await auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
