import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase'; // Import Firebase auth
import { signInWithPopup, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import {
  Box, Button, Flex, VStack, Checkbox, Heading, HStack, Image, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, useDisclosure, InputGroup, Input, InputRightElement, Icon, InputLeftElement, Divider, Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook, faTwitter, faInstagram, faLinkedin, faMicrosoft, faYahoo } from '@fortawesome/free-brands-svg-icons';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { Email as EmailIcon, Lock as LockIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { Google, Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import ReCAPTCHA from 'react-google-recaptcha';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import Face4TwoToneIcon from '@mui/icons-material/Face4TwoTone';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
import axios from 'axios';
import { API_BASE_URL } from './apiConfig';
import { Helmet } from 'react-helmet';

function Login() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [message, setMessage] = useState('');
  const messageTimeout = 5000;
  const [acceptsMarketing, setAcceptsMarketing] = useState(false); // default checked
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const openImageModal = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setAcceptsMarketing(isChecked);

    if (isChecked && email) {
      addToSubscriberDatabase(email);
    }
  };

  const addToSubscriberDatabase = async (email) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/subscribe`, { email: email });

      if (response.status === 200) {
        console.log(response.data.message); // Show success message
      } else {
        console.log(response.data.error); // Show error message
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('An error occurred while subscribing.'); // General error message
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value); // store the captcha value in state
  };

    // Inline styles for provider buttons
  const providerButtonStyle = {
    display: 'block',
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid transparent',
    textAlign: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    color: 'white',
    transition: 'background-color 0.3s, border-color 0.3s'
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in, redirect them to the dashboard
        navigate('/dashboard');
      }
      // No user is signed in, stay on the login page
      });
      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, [navigate]); 
  
  useEffect(() => {
    let timer;
    if (error || message) {
      timer = setTimeout(() => {
        setError('');
        setMessage('');
      }, messageTimeout);
    }
    return () => clearTimeout(timer);
  }, [error, message, messageTimeout]);

  const handleSignInWithProvider = (provider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/dashboard');
      })
      .catch((error) => {
        if (error.code === "auth/invalid-credential") {
          setError("There was a problem with your login credentials. Please try a different login method or retry.");
        } else {
          setError("Authentication failed: " + error.message);
        }
      });
  };

  // For Microsoft login
  const handleMicrosoftLogin = () => {
    const microsoftProvider = new OAuthProvider('microsoft.com');
    handleSignInWithProvider(microsoftProvider);
  };

  // For Yahoo login
  const handleYahooLogin = () => {
    const yahooProvider = new OAuthProvider('yahoo.com');
    handleSignInWithProvider(yahooProvider);
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('')
    if (captchaValue) {
      if (isSignUp) {
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        if (password.length < 6) {
          setError('Password must be at least 6 characters');
          return;
        }

        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          await sendEmailVerification(userCredential.user);
          setMessage('Sign up successful! Please check your email to verify your account.');
          // Check if marketing is accepted and then call the function to add to the database
          if (acceptsMarketing) {
            addToSubscriberDatabase(email);
          }
        } catch (error) {
          setError('Sign-up failed: ' + error.message);
        }
      } else {
        if (password.length < 6) {
          setError('Password must be at least 6 characters');
          return;
        }
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          if (!userCredential.user.emailVerified) {
            setError('Please verify your email before signing in.');
          }
        } catch (error) {
          setError('Authentication failed: ' + error.message);
        }
      }
    } else {
      console.log('Please complete the CAPTCHA');
    }
  };
  
  const handleResetPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        setMessage('Password reset email sent! Please check your email.');
      } catch (error) {
        setError('Failed to send password reset email: ' + error.message);
      }
    } else {
      setError('Please enter your email address to reset your password.');
    }
  };

  // Define inline styles for buttons
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '250px', // Set a minimum width
    padding: '10px 20px',
    margin: '10px auto', // Auto margin for horizontal centering
    borderRadius: '25px',
    border: '2px solid #ddd',
    textAlign: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    color: 'black',
    boxShadow:"0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 2px 2px -2px rgba(0, 0, 0, 0.2)",
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    whiteSpace: 'nowrap', // Prevent text wrapping
    _hover: {
      transform: "scale(1.1)",
      boxShadow: '0 0 5px #9354d6',
      animation: 'glowing 20s linear infinite',
      transition: 'opacity .3s ease-in-out',
    },
  };

  // Styles for different providers
  const googleStyle = { ...buttonStyle, backgroundColor: '#db4437', color: 'white', borderColor: '#db4437' };
  const facebookStyle = { ...buttonStyle, backgroundColor: '#4267b2', color: 'white', borderColor: '#4267b2' };
  const twitterStyle = { ...buttonStyle, backgroundColor: '#1da1f2', color: 'white', borderColor: '#1da1f2' };
  const instagramStyle = { ...buttonStyle, backgroundColor: '#C13584', color: 'white', borderColor: '#C13584' };
  const linkedinStyle = { ...buttonStyle, backgroundColor: '#0077b5', color: 'white', borderColor: '#0077b5' };
  const microsoftStyle = { ...buttonStyle, backgroundColor: '#000', color: 'white', borderColor: '#000' };
  const yahooStyle = { ...buttonStyle, backgroundColor: '#9354d6', color: 'white', borderColor: '#9354d6' };
  const parentContainerStyle = {
    background: 'linear-gradient(135deg, #ebf7f4, #f7f0eb, #f4ebf7, #f5f7eb, #f5f7eb)',
    borderRadius: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border:"1px solid", 
    boxShadow:"0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 2px -2px rgba(0, 0, 0, 0.2)",
    borderColor: "rgba(0, 0, 0, 0.2)"
    // Add any other styles needed for the parent container
  };

  const boxContainerStyle = {
    textAlign: 'center',
    width: 'auto', // Auto width for the Box
    margin: 'auto' // Auto margin for the Box
  };

  return (
  <Flex
    direction={{ base: 'column', md: 'row', lg: 'row' }} // Stack on mobile, row on medium devices and up
    align="center"
    bgGradient='linear-gradient(15deg, #f7f0eb, #f7f0eb, #f4ebf7, #f5f7eb, #f5f7eb)'
    justify="center"
    wrap="wrap" // Ensures wrapping content
    gap={5}
    p={5}
    w="100%"
  > 
    <div>
      <Helmet>
        <title>Login - Access Your PeakMet Analytics Dashboard | PeakMet Analytics</title>
        <meta name="description" content="Login to your PeakMet Analytics account to access advanced AI and ML tools. Manage your projects, view dashboards, and harness powerful analytics from anywhere." />
        <meta name="robots" content="noindex, nofollow" />  // Typically, you might not want to index login pages.
      </Helmet>
      <Heading as="h1" size="xl" color="#000" mb={5}>Login to PeakMet Analytics</Heading>
    </div>
        <Flex direction={{ base: 'column', md: 'row' }} width="100%" justify="center" alignItems="flex-start" mb={5}>
    <Box textAlign="center"
    width={{ base: '100%', md: '30%' }}>
      <Button sx={{...buttonStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }} 
        onClick={onOpen}>
        <AlternateEmailOutlinedIcon style={{ marginRight: '10px' }} size="lg" /> Email Sign Up / Login
      </Button>
      <Button sx={{...googleStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }} 
        onClick={() => handleSignInWithProvider(new GoogleAuthProvider())}>
        <FontAwesomeIcon icon={faGoogle} style={{ marginRight: '10px' }} size="lg" /> Google Login
      </Button>
      <Button sx={{...facebookStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }}  
        onClick={() => handleSignInWithProvider(new FacebookAuthProvider())}>
        <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '10px' }} size="lg" /> Facebook Login
      </Button>
      <Button sx={{...twitterStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }}  
        onClick={() => handleSignInWithProvider(new TwitterAuthProvider())}>
        <FontAwesomeIcon icon={faTwitter} style={{ marginRight: '10px' }} size="lg" /> Twitter Login
      </Button>
      {/* Placeholder buttons for Instagram and LinkedIn */}
      <Button sx={{...instagramStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }}  
        onClick={() => handleSignInWithProvider(new FacebookAuthProvider())}>
        <FontAwesomeIcon icon={faInstagram} style={{ marginRight: '10px' }} size="lg" /> Instagram Login
      </Button>
      <Button sx={{...linkedinStyle,
       '&:hover': {
            transform: "scale(1.1)",
            borderColor: "#ccc", // Example hover border color
          },
        }}  
        onClick={() => {/* Implement LinkedIn OAuth Logic */}}>
        <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '10px' }} size="lg" /> LinkedIn Login
      </Button>
      <Button sx={{...microsoftStyle,
       '&:hover': {
          transform: "scale(1.1)",
          borderColor: "#ccc", // Example hover border color
        },
      }} 
      onClick={() => handleMicrosoftLogin()}>
        <FontAwesomeIcon icon={faMicrosoft} style={{ marginRight: '10px' }} size="lg" /> Microsoft Login
      </Button>
      <Button sx={{...yahooStyle,
       '&:hover': {
          transform: "scale(1.1)",
          borderColor: "#ccc", // Example hover border color
        },
      }} 
      onClick={() => handleYahooLogin()}>
        <FontAwesomeIcon icon={faYahoo} style={{ marginRight: '10px' }} size="lg" /> Yahoo Login
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay 
              bg='none'
              backdropFilter='auto'
              backdropInvert='80%'
              backdropBlur='2px' />
        <ModalContent minW="80vw" borderRadius="15px" 
          transition="background 0.5s ease-in-out" color='#000'>
          <ModalHeader bg="white" color='#263199' borderRadius="15px" marginBottom="10px">
            <Flex justifyContent="center" width="100%" fontSize='3xl' fontFamily="GarnettMedium">
              {isSignUp ? 'Sign Up' : 'Login'}
              {isSignUp ? <ExitToAppTwoToneIcon fontSize='5xl' style={{ margin:"0.5rem"}}/> : <Face4TwoToneIcon fontSize='4xl' style={{ margin:"0.5rem"}}/>}
            <hr style={{ color: '#000', borderColor: '#000', backgroundColor: "#000", height: '10px'  }} />
            </Flex>
            
          </ModalHeader>
          <ModalCloseButton />
            <ModalBody color='#000'>
              <form onSubmit={handleEmailAuth}>
               <InputGroup mt="4">
                  <InputLeftElement pointerEvents="none">
                    <EmailIcon color="gray.300" />
                  </InputLeftElement>
                  <Input type="email" border="1px solid black" boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                   placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>

                <InputGroup mt="8">
                  <InputLeftElement pointerEvents="none">
                    <LockIcon color="gray.100" />
                  </InputLeftElement>
                  <Input type={showPassword ? 'text' : 'password'} border="1px solid black" boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                  placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <InputRightElement onClick={handleClickShowPassword}>
                    <Icon as={showPassword ? VisibilityOff : Visibility} />
                  </InputRightElement>
                </InputGroup>
                {isSignUp && (
                  <InputGroup mt="8">
                    <InputLeftElement pointerEvents="none">
                      <LockIcon color="gray.300" />
                    </InputLeftElement>
                    <Input type={showPassword ? 'text' : 'password'} border="1px solid black" boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' 
                    placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    <InputRightElement onClick={handleClickShowPassword}>
                      <Icon as={showPassword ? VisibilityOff : Visibility} />
                    </InputRightElement>
                  </InputGroup>
                )}

                {error && <Box color="red.500" mt="1rem">{error}</Box>}
                {message && <Text color="green.500" mt="2">{message}</Text>}

                <Box color="#000" m="4" alignItems='center' justifyContent="center" display="flex">
                <ReCAPTCHA
                  sitekey="6Lcga5spAAAAAHdJHady8Rrzz9z-KtUmZSxTtBtx" // Replace YOUR_SITE_KEY with your actual reCAPTCHA site key
                  onChange={onCaptchaChange}
                />
                </Box>
                <ModalFooter mt={4} borderRadius="15px" bg='blue.50' color='#000'>
                  <VStack spacing={8} align="center" justifyContent="center">
                    {/* Checkbox with links to policy documents */}
                    {isSignUp && (
                      <Checkbox colorScheme="green" m={8} isChecked={acceptsMarketing} onChange={handleCheckboxChange}>
                        I accept to receive customized tips tailored to my business, including marketing-related emails from PeakMet.
                        We follow a strict <Link href="/privacy-policy" color="blue.500" isExternal>Privacy Policy</Link>, <Link href="/terms-of-service" color="blue.500" isExternal>Terms </Link> and  <Link href="/data-security-standards" color="blue.500" isExternal>Security</Link>.
                      </Checkbox>
                    )}
                    <HStack justifyContent="center">
                      <Button _hover={{
                          bg: '#f5cb42', // Darker shade on hover
                          transform: 'scale(1.05)', // Slightly increase size
                          boxShadow: 'lg', // Add shadow
                          color: 'black',
                          fontSize: '1.8rem',
                          borderRadius: '40px',
                        }} 
                        boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                      width="35vw" padding="2rem" colorScheme="blue" variant="outline" onClick={onClose} margin="1rem" fontSize="2xl">
                        Close
                      </Button>
                      <Button _hover={{
                          bg: '#5c2cde', // Darker shade on hover
                          transform: 'scale(1.05)', // Slightly increase size
                          boxShadow: 'lg', // Add shadow
                          color: '#fff',
                          fontSize: '1.6rem',
                          borderRadius: '40px',
                        }} 
                        boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' 
                      width="35vw" padding="2rem" colorScheme="green" type="submit" fontSize="2xl">
                        {isSignUp ? 'Sign Up' : 'Login'}
                      </Button>
                    </HStack>
                    {!isSignUp && (
                      <Button _hover={{
                          bg: '#f5cb42', // Darker shade on hover
                          transform: 'scale(1.05)', // Slightly increase size
                          boxShadow: 'lg', // Add shadow
                          color: 'black',
                          fontSize: '1.2rem',
                          borderRadius: '40px',
                        }} padding= "1rem" colorScheme="teal" mt="2" onClick={handleResetPassword} variant="link">
                        Forgot password?
                      </Button>
                    )}
                    <Box textAlign="center">
                      {isSignUp ? (
                        <>
                          Already have an account?{" "}<KeyboardDoubleArrowRightIcon style={{ fontSize: '2xl !important', marginLeft:"1rem"}}/> 
                          <Button _hover={{
                              bg: 'teal.400', // Darker shade on hover
                              transform: 'scale(1.05)', // Slightly increase size
                              boxShadow: 'lg', // Add shadow
                              color: 'white',
                              fontSize: '1.2rem',
                              borderRadius: '40px',
                            }}
                            margin="1rem" padding="1rem" fontSize="xl" colorScheme="green" variant="link" onClick={() => setIsSignUp(false)}>
                            Login here
                          </Button>
                        </>
                      ) : (
                        <>
                          Don’t have an account?{" "}
                          <Button _hover={{
                              bg: 'teal.400', // Darker shade on hover
                              transform: 'scale(1.05)', // Slightly increase size
                              boxShadow: 'lg', // Add shadow
                              color: 'white',
                              fontSize: '1.2rem',
                              borderRadius: '40px',
                            }}
                            margin="1rem" padding="1rem" fontSize="xl" colorScheme="green" variant="link" onClick={() => setIsSignUp(true)}>
                            Sign Up here
                          </Button>
                        </>
                      )}
                    </Box>
                  </VStack>
                </ModalFooter>
              </form>
            </ModalBody>

        </ModalContent>
      </Modal>
    </Box>

      <Divider orientation={{ base: 'horizontal', md: 'vertical' }} display={{ base: 'block', md: 'none' }} />

      <Box display="flex" flexDirection="column" alignItems="center"
        justifyContent="center" borderRadius="lg" width={{ base: '100%', md: '60%' }}>
        <Image src="/webp/login-screen.webp"
          alt="Peakmet Login Screen"
          loading="lazy"
          fallbackSrc="/login-screen.jpg"
           shadow="lg" borderRadius="55px" />

      </Box>
    </Flex>

    {/* Display reviews placeholder image */}
      <Box width="100%">
        <Image
          boxShadow="0px 3px 3px -1px rgba(0, 0, 0, 0.3), 0px 3px 3px -2px rgba(0, 0, 0, 0.2)"
          src="/webp/login-reviews-1.webp"
          alt="Peakmet Reviews"
          fallbackSrc="/login-review-2.png"
          maxW="100%"
          borderRadius="15px"
          loading="lazy"
          onClick={openImageModal}
          cursor="pointer"
        />
      </Box>

      <Modal isOpen={isImageModalOpen} onClose={closeImageModal} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Peakmet Reviews</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src="/webp/login-reviews-1.webp"
              alt="Peakmet Reviews"
              fallbackSrc="/login-review-2.png"
              width="100%"
              borderRadius="15px"
              loading="lazy"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
</Flex>
  );
}

export default Login;
