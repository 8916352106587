import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBz6teni5uX8eKxVtxIzpWZrb_E_2G7e6Q",
  authDomain: "plogin-1c7b2.firebaseapp.com",
  projectId: "plogin-1c7b2",
  storageBucket: "plogin-1c7b2.appspot.com",
  messagingSenderId: "115952894250",
  appId: "1:115952894250:web:0eda1149f78a78d4ae1e53",
  measurementId: "G-2X9J32QKBN"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);

