// StripeSessionContext.js
import React, { createContext, useState, useContext } from 'react';

const StripeSessionContext = createContext();

export const useStripeSession = () => useContext(StripeSessionContext);

export const StripeSessionProvider = ({ children }) => {
    const [stripeSessionId, setStripeSessionId] = useState(null);

    return (
        <StripeSessionContext.Provider value={{ stripeSessionId, setStripeSessionId }}>
            {children}
        </StripeSessionContext.Provider>
    );
};
