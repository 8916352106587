import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from './firebase'; // adjust the import path as necessary
import axios from 'axios';
import { API_BASE_URL } from './apiConfig';

const SubscriptionContext = createContext({
  subscriptionLevel: null,
  setSubscriptionLevel: () => {},
  loading: true
});

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
    const [subscriptionLevel, setSubscriptionLevel] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            // console.log("Auth State Changed. User:", user); // Debug: Log user object
            if (user) {
                try {
                    const idToken = await user.getIdToken();
                    const response = await axios.get(`${API_BASE_URL}/subscriptionDetails`, {
                        headers: { Authorization: `Bearer ${idToken}` }
                    });
                    if (response.data && response.data.plan) {
                        console.log("Setting subscription level to:", response.data.plan); // Debug: Log subscription level
                        setSubscriptionLevel(response.data.plan);
                    } else {
                        // No subscription details found, handle as un-subscribed
                        setSubscriptionLevel(null); // or 'none' or your preferred default
                    }
                } catch (error) {
                    console.error("Error fetching subscription details:", error);
                    setSubscriptionLevel(null);
                } finally {
                    setLoading(false);
                }
            } else {
                // Handle the case when the user is not logged in
                console.log("User not logged in");
                setSubscriptionLevel(null);
                setLoading(false);
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []); // Removed dependency on subscriptionLevel

    return (
        <SubscriptionContext.Provider value={{ subscriptionLevel, setSubscriptionLevel, loading }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
